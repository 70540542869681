import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCard, getCards, changeCardStatus, getSpecialCards } from "../../redux/cardSlice";
import { message, Card, Spin } from "antd";
import SpecialCardsDrawer from "./SpecialCardsDrawer";
import Header from "../../global/Header/Header";
import CustomTableComponents from "../CommonComponents/CustomTableComponents";
import { style } from "../../Styles";
import { MinusOutlined } from "@ant-design/icons";
import ActionButtonWithChangeStatus from "../CommonComponents/ActionButtonWithChangeStatus";
import { useTranslation } from "react-i18next";
import { formatDateAndTime } from "../../Utils/Imports/date-utils";

const SpecialCards = () => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("specialCardScreen.table.cardId"),
      key: "cardId",
      render: (record) => <span>{record.cardNumber || <MinusOutlined />}</span>,
      show: true,
    },
    {
      title: t("specialCardScreen.table.cardType"),
      key: "cardType",
      render: (record) => (
        <span>{record.populatedCardType.name || <MinusOutlined />}</span>
      ),
      show: true,
    },
    {
      title: t("specialCardScreen.table.name"),
      key: "name",
      render: (record) => (
        <span>{record.cardHolder.name || <MinusOutlined />}</span>
      ),
      show: true,
    },
    {
      title: t("specialCardScreen.table.surname"),
      key: "surname",
      render: (record) => (
        <span>{record.cardHolder.surname || <MinusOutlined />}</span>
      ),
      show: true,
    },
    {
      title: t("specialCardScreen.table.idNumber"),
      key: "idNumber",
      render: (record) => (
        <span>{record.cardHolder.identityCardNumber || <MinusOutlined />}</span>
      ),
      show: true,
    },
    {
      title: t("specialCardScreen.table.validFrom"),
      key: "validFrom",
      render: (record) => {
        const date = record.cardValidFrom ? formatDateAndTime(record.cardValidFrom) : <MinusOutlined />;
        return <span>{date}</span>;
      },
      sorter: (a, b) =>
        (a.cardValidFrom || "").localeCompare(b.cardValidFrom || ""),
      show: true,
    },
    {
      title: t("specialCardScreen.table.validTo"),
      key: "validTo",
      render: (record) => {
        const date = record.cardValidTo ? formatDateAndTime(record.cardValidTo) : <MinusOutlined />;
        return <span>{date}</span>;
      },
      sorter: (a, b) =>
        (a.cardValidTo || "").localeCompare(b.cardValidTo || ""),
      show: true,
    },
    {
      title: t("specialCardScreen.table.bundleValidFrom"),
      key: "bundleValidFrom",
      render: (record) => {
        const date = record.bundleValidFrom ? formatDateAndTime(record.bundleValidFrom) : <MinusOutlined />;
        return <span>{date}</span>;
      },
      sorter: (a, b) =>
        (a.bundleValidFrom || "").localeCompare(b.bundleValidFrom || ""),
      show: true,
    },
    {
      title: t("specialCardScreen.table.bundleValidTo"),
      key: "bundleValidTo",
      render: (record) => {
        const date = record.bundleValidTo ? formatDateAndTime(record.bundleValidTo): <MinusOutlined />;
        return <span>{date}</span>;
      },
      sorter: (a, b) =>
        (a.bundleValidTo || "").localeCompare(b.bundleValidTo || ""),
      show: true,
    },
    {
      title: t("validatorScreen.table.status"),
      key: "active",
      render: (record) => {
        return (
          <span style={style.dataContainer}>
            {record.active ? (
              <div style={style.statusActif}>{t("statuses.active")}</div>
            ) : (
              <div style={style.statusInactif}>{t("statuses.inactive")}</div>
            )}
          </span>
        );
      },
      sorter: (a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1),
      show: true,
    },
    {
      title: t("specialCardScreen.table.actions"),
      key: "actions",
      render: (text, record) => (
        <ActionButtonWithChangeStatus
          record={record}
          onDelete={() => handleDeleteSpecialCard(record.id)}
          onRowClick={() => handleSelectedRow(record.id)}
          onChangeStatus={() => handleChangeStatus(record)}
        />
      ),
      show: true,
    },
  ];

  const [isMakingApiCall, setMakingApiCall] = useState(true);
  const [isSpecialCardDrawerVisible, setSpecialCardDrawerVisible] =
    useState(false);
  const [card, setCard] = useState(null);
  const [filter, setFilter] = useState("");
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const dispatch = useDispatch();
  const { cards } = useSelector((state) => state.cards);

  const fetchCards = (page = 1, pageSize = 10) => {
    dispatch(getSpecialCards({page, pageSize}))
      .unwrap()
      .then(() => {
        setMakingApiCall(false);
      })
      .catch(() => {
        setMakingApiCall(false);
      });
  };

  useEffect(() => {
    fetchCards();
  }, [dispatch]);

  const reload = () => {
    // setReloadFlag((prev) => !prev);
    fetchCards();
  };


  const handleChangeStatus = (record) => {
    dispatch(
      changeCardStatus({
        id: record.id,
        status: !record.active,
      })
    )
      .unwrap()
      .then(() => {
        message.success(
          t("specialCardsScreen.messages.specialCardStatusChangedSuccessfully")
        );
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const filteredSpecialCards = cards?.data?.filter((item) => {
    return (
      item.cardHolder != null &&
      item.populatedCardType != null &&
      (
        item.cardNumber
          .toString()
          .toLowerCase()
          ?.includes(filter.toLowerCase()) ||
        item.populatedCardType.name
          .toLowerCase()
          .includes(filter.toLowerCase()) ||
        item.cardHolder.name.toLowerCase().includes(filter.toLowerCase()) ||
        item.cardHolder.surname.toLowerCase().includes(filter.toLowerCase()) ||
        item.cardHolder.identityCardNumber.toLowerCase().includes(filter.toLowerCase())
      )
    );
  });

  const openDrawer = () => {
    setSpecialCardDrawerVisible(true);
  };

  const closeDrawer = () => {
    setSpecialCardDrawerVisible(false);
    setCard(null);
  };

  const handleSelectedRow = (record) => {
    setCard(record);
    setSpecialCardDrawerVisible(true);
  };

  const handleDeleteSpecialCard = (id) => {
    dispatch(deleteCard(id))
      .unwrap()
      .then(() => {
        message.success(t("messages.specialCardDeletedSuccessfully"));
        fetchCards();
      })
      .catch((e) => {
        message.error(e.message);
      });
  };

  const handleGenerateCsv = (event, done) => {
    const promise = async () => {
      setCsvHeaders([
        {
          label: "Card Number",
          key: "cardNumber",
        },
        {
          label: "Card Type",
          key: "cardType",
        },
        {
          label: "Name",
          key: "name",
        },
        {
          label: "Surname",
          key: "surname",
        },
        {
          label: "Date of Birth",
          key: "dateOfBirth",
        },
        {
          label: "ID Number",
          key: "idNumber",
        },
        {
          label: "Email",
          key: "email",
        },
        {
          label: "Mobile",
          key: "mobile",
        },
        {
          label: "Card Valid From",
          key: "cardValidFrom",
        },
        {
          label: "Card Valid To",
          key: "cardValidTo",
        },
      ]);

      setCsvData(csvData);
    };

    promise().then(done());
  };

  return (
    <>
      {isMakingApiCall ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Header
            addButtonText={t("specialCardScreen.header.addSpecialCard")}
            onAddButtonClick={openDrawer}
            onSearch={(e) => {
              setFilter(e.target.value);
            }}
            searchPlaceholder={t("specialCardScreen.header.search")}
            addButtonTextColor={"#ffffff"}
            onReload={fetchCards}
            showAddButton={true}
            BreadcrumbItems={[
              {
                title: t("specialCardScreen.header.title"),
              },
            ]}
            showBreadcrumb={true}
            showExportCSV={true}
            csvHeaders={csvHeaders}
            csvData={filteredSpecialCards}
            generateCSVfn={handleGenerateCsv}
            csvDataMiddleFileName={"SpecialCards"}
          />
          <Card style={{ margin: 16, height: "85vh" }}>
            <CustomTableComponents
              isMakingApiCall={isMakingApiCall}
              columns={columns.filter((column) => column.show)}
              dataArray={filteredSpecialCards}
              handleDoubleClickRow={(record) => handleSelectedRow(record)}
              handleSelectedRow={() => {}}
              paginationMeta={cards?.totalCards}
              onPageChange={(page, pageSize) => fetchCards(page, pageSize)}
            />
          </Card>
          {isSpecialCardDrawerVisible && (
            <SpecialCardsDrawer
              specialCardId={card?.id}
              specialCardHolderId={card?.cardHolder?.id}
              onClose={closeDrawer}
              reload={reload}
            />
          )}
        </>
      )}
    </>
  );
};

export default SpecialCards;
