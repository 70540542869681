import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import HttpStatus from "http-status-codes";
import { isCancel } from "axios";
import { setLoggedIn } from "../redux/authSlice"; // Removed setToken, since token is in cookies

import axiosInstance from "./index";
import {
  showAuthorizationFailedErrorMessage,
  showConnectivityErrorMessage,
  showHasReferenceErrorMessage,
  showRequestLargerThanMaxSizeErrorMessage,
  showUnhandledErrorMessage,
} from "../Utils/Imports/ShowErrors";

const AxiosInterceptor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const _retry = useRef(false);

  useEffect(() => {
    const reqInterceptor = (config) => {
      config.withCredentials = true; // Ensure cookies are sent with requests
      return config;
    };

    const reqErrInterceptor = (error) => {
      return Promise.reject(error);
    };

    const resInterceptor = (res) => {
      return res;
    };

    const resErrInterceptor = async (err) => {
      const originalConfig = err.config;
      if (!isCancel(err)) {
        const status = err?.response?.status;
        if (status == null) {
          showConnectivityErrorMessage();
        } else if (
          status === 401 &&
          originalConfig.url !== "/login" &&
          originalConfig.url !== "/refresh-token" &&
          _retry.current === false
        ) {
          // REFRESH TOKEN (Cookies should automatically send refresh token)
          _retry.current = true;
          try {
            const response = await axiosInstance.post(
              "/refresh-token",
              {},
              { withCredentials: true } // Ensure cookies are sent
            );
            dispatch(setLoggedIn(response.data)); // Assuming the API response includes updated session data
            _retry.current = false;
            return axiosInstance(originalConfig);
          } catch (_error) {
            console.error("Refresh token failed. Redirecting to login.");
            dispatch(setLoggedIn([])); // Clear session state
            clearCookies();
            navigate("/app/login", { replace: true });
            return Promise.reject(_error);
          }
        } else if (status === HttpStatus.FORBIDDEN) {
          showAuthorizationFailedErrorMessage();
        } else if (status === HttpStatus.NOT_FOUND) {
          // skip
        } else if (status === HttpStatus.LOCKED) {
          showHasReferenceErrorMessage();
        } else if (status === HttpStatus.REQUEST_TOO_LONG) {
          showRequestLargerThanMaxSizeErrorMessage();
        } else {
          showUnhandledErrorMessage();
        }
      }
      return Promise.reject(err);
    };

    const reqUseInterceptor = axiosInstance.interceptors.request.use(reqInterceptor, reqErrInterceptor);
    const resUseInterceptor = axiosInstance.interceptors.response.use(resInterceptor, resErrInterceptor);

    return () => {
      axiosInstance.interceptors.request.eject(reqUseInterceptor);
      axiosInstance.interceptors.response.eject(resUseInterceptor);
    };
  }, [dispatch, navigate]);

  return "";
};

const clearCookies = () => {
	document.cookie = "remember-me=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	document.cookie = "refresh-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
	document.cookie = "JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  };

export default AxiosInterceptor;
